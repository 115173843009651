import Typography from "typography";
import { colors } from './colors';

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.666,
  headerFontFamily: ["Inconsolata", "monospace"],
  bodyFontFamily: ["Inconsolata", "monospace"],
  bodyColor: colors.hotel,
  headerColor: colors.bravo,
  overrideThemeStyles: ({ rhythm }, options, styles) => ({
    html: {
      backgroundColor: colors.delta,
    },
    h1: {
      marginBottom: rhythm(1 / 2),
      lineHeight: rhythm(2),
    },
    h3: {
      lineHeight: rhythm(1.1),
      letterSpacing: "-1px",
    },
    h4: {
      lineHeight: rhythm(1.1),
      letterSpacing: "-1px",
      marginBottom: "5px",
    },
    a: {
      textDecoration: "none",
      color: colors.alfa,
    },
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
