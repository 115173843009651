// export const colors = {
//   alfa: "#33ffcf",
//   bravo: "#f5f9ff",
//   charlie: "#8892b0",
//   delta: "#0a192f",
//   foxtrot: "#ccd6f6",
//   golf: "#162841",
//   hotel: "#020c1b",
// };

export const colors = {
  alfa: '#37ed7e',
  bravo: '#363636',
  charlie: '#7a7a7a',
  delta: '#fafafa',
  foxtrot: '#ccd6f6',
  golf: '#172e4f',
  hotel: '#051b3e',
};